<template>
  <div class="w-content">
    <div class="">
      <h1 class="title compressed">Smart contract will be created</h1>
      <h3 class="">
        After the operation is completed, a redirect will occur.
        Please wait for the operation to complete.
      </h3>
    </div>
    <a @click="executeTransHandler">Create smart contract</a>
  </div>
</template>
<script>
import { mapState } from "vuex";
import  instance from "../http";
import i18n from "@/i18n";
export default {
  data: {
    transPackageStr: ""
  },
  methods: {
    executeTransHandler: function() {
      //console.log("createTokenData:");
      console.log(this.createTokenData);

      let _signature = nacl.sign.detached(
        Base58.decode(this.createTokenData.transactionPackagedStr),
        Base58.decode(this.privateKey)
      );

      //console.log("SIGNATURE: ", _signature);
      let postData = this.createTokenData;
      postData.transactionSignature = Base58.encode(_signature);

      let headers = {
        "Content-Type": "application/json"
      };
      let msgResult = "smart contract was created!";
      instance
        .post("../api/wallet/executeTransaction", postData, { headers })
        .then(r => {
          console.log("RESPonse:");
          console.log(r);
          if (r.data.success === true) {
            console.log(msgResult);
            alert(msgResult);
            this.$router.push(`/${i18n.locale}/wallet/perform-transaction`);
          } else {
            msgResult = r.data.message;
            console.log(msgResult);
            alert(msgResult);
          }
        });
    }
  },
  computed: {
    ...mapState({
      createTokenData: state => state.createTokenData,
      privateKey: state => state.privateKey,
      publicKey: state => state.publicKey
    })
  }
};
</script>
